import React, { useState } from "react";
import {
  IonAvatar,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonList,
  IonModal,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonText,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import { bandage } from "ionicons/icons";

import Axios from "axios";
import avatar from "../res/avatar.svg";
import { generateToast } from "../functions";

interface singleUserData {
  _id: string;
  additionalBeeLength: number;
  autoRotatingBeeLength: number;
  lastUpdate: string;
  multiplierLevel: number;
  settingClickingAid: boolean;
  settingNewUI: boolean;
  userImage: string;
  userName: string;
}

interface userData {
  status: number;
  result: singleUserData[];
}

const UsersPage: React.FC = () => {
  enum sortBys {
    NAME,
    LEVEL,
    AUTODREHER,
    BIENEN,
    MULTIPLIER,
  }

  const [selectedUser, setSelectedUser] = useState<singleUserData | undefined>(
    undefined
  );

  const [sortBy, setSortBy] = useState<sortBys>(sortBys.LEVEL);
  const [sorterOpen, setSorterOpen] = useState<boolean>(false);
  const [data, setData] = useState<userData | undefined>();

  const getLevel = (user: {
    additionalBeeLength: number;
    multiplierLevel: number;
    autoRotatingBeeLength: number;
  }) => {
    return (
      user.autoRotatingBeeLength * 5 +
      user.multiplierLevel * 2 +
      user.additionalBeeLength * 3
    );
  };

  const getData = (da: userData, _sortBy: sortBys): userData => {
    switch (sortBy) {
      case sortBys.NAME: {
        return {
          ...da,
          result: da.result.sort((a, b) =>
            b.userName.localeCompare(a.userName)
          ),
        };
      }
      case sortBys.LEVEL: {
        return {
          ...da,
          result: da.result.sort((a, b) => getLevel(b) - getLevel(a)),
        };
      }
      case sortBys.AUTODREHER: {
        return {
          ...da,
          result: da.result.sort(
            (a, b) => b.autoRotatingBeeLength - a.autoRotatingBeeLength
          ),
        };
      }
      case sortBys.BIENEN: {
        return {
          ...da,
          result: da.result.sort(
            (a, b) => b.additionalBeeLength - a.additionalBeeLength
          ),
        };
      }
      case sortBys.MULTIPLIER: {
        return {
          ...da,
          result: da.result.sort(
            (a, b) => b.multiplierLevel - a.multiplierLevel
          ),
        };
      }
      default: {
        return da;
      }
    }
  };

  const getRightProp = (user: singleUserData, sortBy: sortBys): string => {
    switch (sortBy) {
      case sortBys.MULTIPLIER:
        return user.multiplierLevel.toString();

      case sortBys.AUTODREHER:
        return user.autoRotatingBeeLength.toString();
      case sortBys.LEVEL:
        return getLevel(user).toString();

      case sortBys.BIENEN:
        return user.additionalBeeLength.toString();

      default:
        return "";
    }
  };

  useIonViewWillEnter(() => {
    Axios.get(
      (process.env.react_app_apiurl ??
        "https://api.ichbineinebiene.toastbrot.org/") + "api/v1/users2/get"
    )
      .then((e) => {
        if (e.status === 200) {
          console.log(e);
          setData(e.data);
        } else {
          generateToast("Fehler beim Laden");
        }
      })
      .catch(() => generateToast("Fehler beim Laden"));
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" />
          </IonButtons>
          <IonTitle>Benutzer</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => setSorterOpen(true)}>Sortieren</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList lines="none">
          {getData(data ?? { status: 0, result: [] }, sortBy).result.map(
            (e) => (
              <IonItem detail button onClick={() => setSelectedUser(e)}>
                <IonAvatar slot="start">
                  <img
                    src={e.userImage !== "" ? e.userImage : avatar}
                    alt="UserImage"
                  />
                </IonAvatar>
                {e.userName.length ? e.userName : e._id.slice(0, 5) + "..."} (
                {e.autoRotatingBeeLength * 5 +
                  e.additionalBeeLength * 3 +
                  e.multiplierLevel * 2}
                ){e.settingClickingAid ? <IonIcon icon={bandage} /> : ""}
                <IonText slot="end">{getRightProp(e, sortBy)}</IonText>
              </IonItem>
            )
          )}
        </IonList>
        <IonItemDivider>Statistiken</IonItemDivider>
        <IonItem>
          Gesamt: <IonText slot="end">{data?.result.length}</IonText>
        </IonItem>
        <IonItem>
          Ohne Namen:{" "}
          <IonText slot="end">
            {data?.result.filter((e) => e.userName === "").length}
          </IonText>
        </IonItem>
        <IonItem>
          Mit Clicking Aid:
          <IonText slot="end">
            {data?.result.filter((e) => e.settingClickingAid).length.toString()}
          </IonText>
        </IonItem>
        <IonItem>
          Mit neuer UI:
          <IonText slot="end">
            {data?.result.filter((e) => e.settingNewUI).length.toString()}
          </IonText>
        </IonItem>
        <IonItem>
          Max. Auotdreher:
          <IonText slot="end">
            {
              data?.result.sort(
                (a, b) => b.autoRotatingBeeLength - a.autoRotatingBeeLength
              )[0].autoRotatingBeeLength
            }{" "}
            (
            {
              data?.result.sort(
                (a, b) => b.autoRotatingBeeLength - a.autoRotatingBeeLength
              )[0].userName
            }
            )
          </IonText>
        </IonItem>
        <IonItem>
          Max. Bienen:
          <IonText slot="end">
            {data?.result
              .sort((a, b) => b.additionalBeeLength - a.additionalBeeLength)[0]
              .additionalBeeLength.toString()}{" "}
            (
            {
              data?.result.sort(
                (a, b) => b.additionalBeeLength - a.additionalBeeLength
              )[0].userName
            }
            )
          </IonText>
        </IonItem>

        <IonModal onDidDismiss={() => setSorterOpen(false)} isOpen={sorterOpen}>
          <IonHeader>
            <IonToolbar>
              <IonTitle>Sortieren nach...</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setSorterOpen(false)}>
                  Schließen
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonRadioGroup
              value={sortBy}
              onIonChange={(e) => setSortBy(e.detail.value)}>
              <IonItem>
                <IonRadio slot="start" value={sortBys.NAME} />
                Name
              </IonItem>
              <IonItem>
                <IonRadio slot="start" value={sortBys.AUTODREHER} />
                Autodreher
              </IonItem>
              <IonItem>
                <IonRadio slot="start" value={sortBys.LEVEL} />
                Level
              </IonItem>
              <IonItem>
                <IonRadio slot="start" value={sortBys.MULTIPLIER} />
                Multiplier
              </IonItem>
              <IonItem>
                <IonRadio slot="start" value={sortBys.BIENEN} />
                Bienen Anzahl
              </IonItem>
            </IonRadioGroup>
          </IonContent>
        </IonModal>

        <IonModal
          onDidDismiss={() => setSelectedUser(undefined)}
          isOpen={selectedUser !== undefined}>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton onClick={() => setSelectedUser(undefined)}>
                  Zurück
                </IonButton>
              </IonButtons>
              <IonTitle>Benutzer</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>
                  {selectedUser?.userName !== ""
                    ? selectedUser?.userName
                    : "..."}
                </IonCardTitle>
                <IonCardSubtitle>{selectedUser?._id}</IonCardSubtitle>
              </IonCardHeader>
              <IonCardContent>
                <p>Autodreher {selectedUser?.autoRotatingBeeLength}</p>
                <p>Bienen {(selectedUser?.additionalBeeLength ?? 0) + 1}</p>
                <p>Multiplier {selectedUser?.multiplierLevel}</p>
                <p>
                  Letzter Upload{" "}
                  {new Date(
                    selectedUser?.lastUpdate ?? Date.now()
                  ).toLocaleString()}
                </p>
                <p>
                  Nutzt die {selectedUser?.settingNewUI ? "neue" : "alte"} UI
                </p>
                <p>
                  Nutzt {selectedUser?.settingClickingAid ? "" : "nicht"} den
                  separaten Button
                </p>
              </IonCardContent>
            </IonCard>
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default UsersPage;
